import React, { useEffect, useRef, useState } from "react";
import { useWallet } from "use-wallet";

import config, { HALF_YEAR_APY, ANNUAL_APY } from "../../config";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import web3 from "web3";
import axios from "axios"
function Home(props) {
  const wallet = useWallet();
  const [accountBalance, setAccountBalance] = useState("-");
  const [showPopUp, setShowPopUp] = useState(false);
  const [tokenAllowance, setTokenAllowance] = useState(0);

  const [depositData, setDepositData] = useState([]);

  const [totalAnnualStakedAmount, setTotalAnnualStakedAmount] = useState("-");
  const [totalHalfYearRewardAmount, setTotalHalfYearRewardAmount] = useState("-");

  const [totalHalfYearClaimAmount, setTotalHalfYearClaimAmount] = useState("-");
  const [totalAnnualclaimAmount, setTotalAnnualClaimAmount] = useState("-");

  const [maxAnnualReward, setMaxAnnualReward] = useState("-");
  const [maxHalfYearReward, setMaxHalfYearReward] = useState("-");
  const [upline, setUpline] = useState("-");

  const [coinPrice,setCoinPrice] = useState("-")
  const [refReward,setRefReward] = useState("-")

  const [halfYearRewardWithdrawn,setHalfYearRewardWithdrawn] = useState("-")
  const [annualRewardWithdrawn,setAnnualRewardWithdrawn] = useState("-")

  
  const [halfYearRemainingReward,setHalfYearRemainingReward] = useState("-")
  const [annualRemainingReward,setAnnualRemainingReward] = useState("-")

  
  

  useEffect(() => {
    wallet.connect();
    loadTokenPrice()
  }, []);

  const loadTokenPrice = async()=>{
    let resp = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=ammyi-coin&vs_currencies=usd")
    setCoinPrice((resp.data['ammyi-coin'].usd).toFixed(3))
  }

  useEffect(() => {
    if (wallet.account && props.contracts && props.contracts.tokenContract) {
      setInterval(() => {
        loadBalance(wallet.account, props.contracts.tokenContract);
      }, 5000);
    }

    console.log("props.contract.tokenContract", props.contracts);
  }, [wallet.account, props.contracts]);

  useEffect(() => {
    if (wallet.account && props.contracts && props.contracts.mlmContract) {
      loadMLMData(wallet.account, props.contracts.mlmContract);
    }
  }, [wallet.account, props.contracts]);

  const copyLink = () => {
    let data = window.location.origin + "?masternode=" + wallet.account;

    var input = document.createElement("input");
    input.setAttribute("value", data);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    document.body.removeChild(input);
    return result;
  };

  const refLinkCopy = () => {
    if (wallet.account) {
      toast.success("🦄 Referral Link Copied", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      copyLink();
    } else {
      toast.error("Please Connect Wallet", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const loadBalance = async (account, contract) => {

    let tokenBalance = await contract.methods.balanceOf(account).call();
    let _tokenAllowance = await contract.methods
      .allowance(account, config.MLM_ADDRESS)
      .call();
    setTokenAllowance((_tokenAllowance / 1e18).toFixed(2));

    setAccountBalance((tokenBalance / 1e18).toFixed(2));
  };

  const getBalance = () => {
    if (wallet.balance != -1) {
      return (wallet.balance / 1e18).toFixed(4);
    } else {
      return "-";
    }
  };

  const loadMLMData = async (account, contract) => {
    // account = "0xd5B8D0B8822A775Ff416A15FaDf7e67f44993A05"
    let totalDeposit = await contract.methods.getDepositLength(account).call();

    let deposits = [];

    for (let i = 0; i < totalDeposit; i++) {
      console.log("deposit,i",account,i)
      let deposit = await contract.methods.getDeposit(account, i).call();

      deposit = {
        amount: deposit.amount/1e18,
        dividentWithdrawn: deposit.dividentWithdrawn/1e18,
        isFinshed: deposit.isFinshed,
        isHalfYearly: deposit.isHalfYearly,
        startDate: deposit.startDate,
      };


      deposits.push(deposit);
    }

    let pendingRewards = await contract.methods
    .getPendingReward(account)
    .call();

    console.log("pendingRew",pendingRewards)
  let halfYearClaimReward = (pendingRewards[0]/1e18);
  let annualClaimReward = (pendingRewards[1]/1e18 );
  setTotalAnnualClaimAmount(annualClaimReward);
  setTotalHalfYearClaimAmount(halfYearClaimReward);
    let _refReward =  await contract.methods.refEarnings(account).call();
    _refReward = _refReward/1e18;
    setRefReward(_refReward);
    let _upline = await contract.methods.getReferral(account).call();

    setUpline(_upline);
 


    setDepositData(deposits)
    setDepositsData(deposits);
  };

  const setDepositsData = (deposits) => {


    // const [totalAnnualStakedAmount,setTotalAnnualStakedAmount ] = useState("-");
    // const [totalHalfYearRewardAmount,setTotalHalfYearRewardAmount ] = useState("-");

    // const [totalHalfYearClaimAmount,setTotalHalfYearClaimAmount ] = useState("-");
    // const [totalAnnualclaimAmount,setTotalAnnualClaimAmount ] = useState("-");

    // const [maxAnnualReward,setMaxAnnualReward ] = useState("-");
    // const [maxHalfYearReward,setMaxHalfYearReward ] = useState("-");

    let totalAnnualStaked = 0;
    let totalAnnualRewardToBeGet = 0;

    let totalHalfYearStaked = 0;
    let totalhalfYearRewardToBeGet = 0;


    let totalAnnualRewardWithdrawn = 0;
    let totalHalfYearRewardWithdrawn = 0;

    

    for (let deposit of deposits) {
      let maxReward;
      if (!deposit.isFinshed) {
        if (deposit.isHalfYearly) {
          totalHalfYearStaked = totalHalfYearStaked + deposit.amount;
          maxReward = (deposit.amount * HALF_YEAR_APY) / 100;
          totalhalfYearRewardToBeGet = totalhalfYearRewardToBeGet + maxReward;
          totalHalfYearRewardWithdrawn = totalHalfYearRewardWithdrawn+deposit.dividentWithdrawn

        } else {
          totalAnnualStaked = totalAnnualStaked + deposit.amount;
          maxReward = (deposit.amount * ANNUAL_APY) / 100;
          totalAnnualRewardToBeGet = totalAnnualRewardToBeGet + maxReward;
          totalAnnualRewardWithdrawn  = totalAnnualRewardWithdrawn+deposit.dividentWithdrawn

        }
      }
    }



    setHalfYearRemainingReward((totalhalfYearRewardToBeGet-totalHalfYearRewardWithdrawn).toFixed(4))
    setAnnualRemainingReward((totalAnnualRewardToBeGet-totalAnnualRewardWithdrawn).toFixed(4))


    setHalfYearRewardWithdrawn(totalHalfYearRewardWithdrawn.toFixed(4));
    setAnnualRewardWithdrawn(totalAnnualRewardWithdrawn.toFixed(4))
    setTotalAnnualStakedAmount(totalAnnualStaked.toFixed(2));
    setTotalHalfYearRewardAmount(totalHalfYearStaked.toFixed(2));

    setMaxAnnualReward(totalAnnualRewardToBeGet.toFixed(2));
    setMaxHalfYearReward(totalhalfYearRewardToBeGet.toFixed(2));
  };

  const getShortLink = () => {
    if (upline === "-") {
      return upline;
    }

    let link = upline.substring(0, 4) + "..." + upline.slice(-4);
    return link;
  };

  const handleAllowance = async () => {
    let maxAmount = web3.utils.toWei("10000000000000000000000000000000000");
    await props.contracts.tokenContract.methods
      .approve(config.MLM_ADDRESS, maxAmount)
      .send({ from: wallet.account });
  };

  const getRefAccount = () => {
    let ref = localStorage.getItem("masternode");
    console.log("getRefAccount", ref);
    if (!ref || ref == "null" || ref == null) {
      return config.DEFAULT_REF;
    } else {
      return ref;
    }
  };
  const stakeNow = async (amount, isHalfYearly) => {
    if (props.contracts && props.contracts.mlmContract && wallet.account) {
      amount = web3.utils.toWei(amount);

      console.log("tokenAllowance", tokenAllowance);
      if (Number(tokenAllowance) < Number(amount)) {
        await handleAllowance();
      }

      console.log("pointter", amount, isHalfYearly, getRefAccount());

      await props.contracts.mlmContract.methods
        .invest(amount, isHalfYearly, getRefAccount())
        .send({ from: wallet.account });

      if (wallet.account && props.contracts && props.contracts.mlmContract) {
        loadMLMData(wallet.account, props.contracts.mlmContract);
      }
    }
  };

  const StakePopUp = (props) => {
    const [isAnnualPlan, setIsAnnualPlan] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [mode, setMode] = useState(0);
    const [stakingInput, setStakingInput] = useState();

    const handleStakeNow = async () => {
      let value = stakingInput;
      setLoading(true);
      try {
        await stakeNow(value, !isAnnualPlan);
        props.onPopupClose();
      } catch {}
      setLoading(false);
    };

  
  
    return (
      <div>
        {props.show?  <div className="popup-card-section">
          <div className="popup-card-bg">
            <div className="popup-card-heading">
              <h3>Stake AMMYI Coin</h3>
              <div
                className="close-popup-card"
                onClick={() => {
                  props.onPopupClose();
                }}
              >
                <i className="fas fa-times" />
              </div>
            </div>
            <div className="popup-card firstTable">
              {mode == 0 ? (
                <div id="formSubmit">
                  <table className="top-table">
                    <tbody>
                      <tr style={{ borderBottom: "none !important" }}>
                        <td rowSpan={2}>Tenure</td>
                        <td colSpan={2} style={{ textAlign: "start" }}>
                          <input
                            type="radio"
                            name="tenure"
                            checked={!isAnnualPlan}
                            id="halfYearly"
                            onChange={() => {
                              setIsAnnualPlan(false);
                            }}
                            defaultValue="Half Yearly"
                            className="checkbox-class"
                            required
                          />
                          <label htmlFor="halfYearly">Half Yearly</label>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} style={{ textAlign: "start" }}>
                          <input
                            type="radio"
                            checked={isAnnualPlan}
                            onChange={() => {
                              setIsAnnualPlan(true);
                            }}
                            name="tenure"
                            id="yearly"
                            defaultValue="Yearly"
                            className="checkbox-class"
                            required
                          />
                          <label htmlFor="yearly">Yearly</label>
                        </td>
                      </tr>

                      <tr>
                        <td>Number of AMMYI</td>
                        <td colSpan={2}>
                          <input
                            type="number"
                            min={0}
                            value={stakingInput}
                            // ref={inputRef}
                            onChange={(n) => {
                              console.log("cdcxcxcxcx", n.target.value);
                              setStakingInput(n.target.value);
                            }}
                            placeholder="Enter Amount"
                            className="primary-input"
                            required
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>APR (%)</td>
                        <td colSpan={2}>
                          <input
                            type="number"
                            value={
                              !isAnnualPlan
                                ? config.HALF_YEAR_APY
                                : config.ANNUAL_APY
                            }
                            disabled
                            min={0}
                            className="primary-input"
                            readOnly
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <button
                    onClick={() => {
                      // setStakingAmount(inputRef.current.value);
                      setMode(1);
                    }}
                    className="secondary-btn next-btn"
                  >
                    {/* {isLoading? <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>:"Stake"} */}
                    Next
                  </button>
                </div>
              ) : (
                <div>
                  <table className="bottom-table">
                    <thead>
                      <tr>
                        <td colSpan={2}>Summary</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Tenure</td>
                        <td className="tenureYearValue" id="tenureYearValue">
                          {isAnnualPlan ? "Yearly" : "Half Yearly"}
                        </td>
                      </tr>
                      <tr>
                        <td>APR</td>
                        <td className="aprAmountValue">
                          {isAnnualPlan
                            ? config.ANNUAL_APY + "%"
                            : config.HALF_YEAR_APY + "%"}
                        </td>
                      </tr>
                      <tr>
                        <td>No. of AMMYI</td>
                        <td className="tokenAmount" id="stakeAmount">
                          stakingAmount
                        </td>
                      </tr>
                      <tr>
                        <td>Start Date</td>
                        <td id="startDate">{moment().format("DD MMM YYYY")}</td>
                      </tr>
                      <tr>
                        <td>End Date</td>
                        <td id="endDate">
                          {moment()
                            .add(isAnnualPlan ? 12 : 6, "months")
                            .format("DD MMM YYYY")}
                        </td>
                      </tr>
                      <tr>
                        <td>Total Reward</td>
                        <td className="totalPrice">
                          {isAnnualPlan
                            ? (config.ANNUAL_APY * parseInt(stakingInput)) / 100
                            : (config.HALF_YEAR_APY * parseInt(stakingInput)) /
                              100}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <button
                    onClick={() => {
                      handleStakeNow();
                    }}
                    className="secondary-btn next-btn"
                  >
                    {isLoading ? (
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Stake Now"
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
     :null}
      </div>
    );
  };
  const handleClaim = async (isAnnual) => {
    if (!wallet.account) {
      toast.error("Please connect your wallet");
      return;
    }
    if (props.contracts && props.contracts.mlmContract) {
      await props.contracts.mlmContract.methods
        .claimReward(isAnnual)
        .send({ from: wallet.account });

        if (wallet.account && props.contracts && props.contracts.mlmContract) {
          loadMLMData(wallet.account, props.contracts.mlmContract);
        }
    }
  };

  const handleWithdraw = async () => {
    if (!wallet.account) {
      toast.error("Please connect your wallet");
      return;
    }
    if (props.contracts && props.contracts.mlmContract) {
      await props.contracts.mlmContract.methods
        .withdraw()
        .send({ from: wallet.account });

        if (wallet.account && props.contracts && props.contracts.mlmContract) {
          loadMLMData(wallet.account, props.contracts.mlmContract);
        }
    }
  };
  return (
    <div className="dashboard-container">

     <StakePopUp
        show={showPopUp}
        onPopupClose={() => {
          setShowPopUp(false);
        }}
      />
      <div className="navbar-toggle">
        <i className="fas fa-bars" />
      </div>

      <div className="sidebar">
        <div className="logo">
          <a href="../index.html">
            <img src="./assets/images/ami logo.png" alt="" />
          </a>
        </div>
        <nav>
          <ul>
            <li className="active">
              <a href="index.html">
                <i className="fas fa-columns" />
              </a>
            </li>
            <li>
              <a href="leader.html">
                <i className="fas fa-chart-line" />
              </a>
            </li>
            <li>
              <a href="user.html">
                <i className="fas fa-user" />
              </a>
            </li>
            <li className="rotate">
              <a href="settings.html">
                <i className="fas fa-cog" />
              </a>
            </li>
          </ul>
        </nav>
        <div className="sidebar-footer">
          <li>
            <i className="fas fa-sign-out-alt" />
          </li>
        </div>
      </div>

      <main>
        <div className="main-content">
          <div className="dash-header">
            <div style={{ maxWidth: "450px", width: "100%" }}>
              <div className="dashboard-heading flex">
                <p style={{ margin: 0, padding: 0 }}>
                  <i className="fas fa-columns" />
                </p>
                <div className="name flex">
                  <div className="letters letters-0 ×">D</div>
                  <div className="letters letters-1 A">A</div>
                  <div className="letters letters-2 L">S</div>
                  <div className="letters letters-3 E">H</div>
                  <div className="letters letters-4 X">B</div>
                  <div className="letters letters-5 ×">O</div>
                  <div className="letters letters-6 B">A</div>
                  <div className="letters letters-7 R">R</div>
                  <div className="letters letters-8 O">D</div>
                </div>
                <p />
              </div>
              <div className="dashboard-small-heading flex">
                <p>
                  <i
                    className="fas fa-wallet"
                    style={{ color: "rgb(255,195,115)" }}
                  />
                  BNB :{" "}
                  <span id="myBal">
                    {getBalance()}
                    <span></span>
                  </span>
                </p>
                <p onclick="openContract()" style={{ cursor: "pointer" }}>
                  <span id="cAddress">{wallet.account}</span>
                </p>
              </div>
            </div>
            <div className="flexcolumn">
              <div className="header-button flex">
                {/* <div className="primary-btn"><span id="address">
                  Your Wallet Address</span>
              </div> */}
                <div
                  className="secondary-btn"
                  onClick={() => {
                    wallet.connect();
                  }}
                >
                  {wallet.account ? "Connected" : "Connect"}
                </div>
              </div>
            </div>
          </div>
          <div className="dash-content mh-450">
            <div className="row mh-450">
              <div className="col-md-6">
                <div
                  className="dash-wallet"
                  style={{ minHeight: "570px !important" }}
                >
                  <div className="wallet-value">
                    <p>Total Balance</p>
                    <h2 id="totalBalance">{accountBalance}</h2>
                    <p>AMY</p>
                  </div>
                  <div className="row wallet-value-info">
                    <div className="col-md-6 col-6">
                      <div className="wallet-info">
                        <p>Annual APR</p>
                        <div className="wallet-info-amount">24%</div>
                      </div>
                    </div>
                    <div className="col-md-6 col-6">
                      <div className="wallet-info">
                        <p>H/Y APR%</p>
                        <div className="wallet-info-amount">10%</div>
                      </div>
                    </div>
                    <div className="col-md-6 col-6">
                      <div className="wallet-info">
                        <p>Total Staked</p>
                        <div className="wallet-info-amount">
                          <span id="ystaked">{totalAnnualStakedAmount}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-6">
                      <div className="wallet-info">
                        <p>Total Staked</p>
                        <div className="wallet-info-amount">
                          <span id="hstaked">{totalHalfYearRewardAmount}</span>
                        </div>
                      </div>
                    </div>
                    
                    <div className="col-md-6 col-6">
                      <div
                        className="wallet-info"
                        style={{
                          marginBottom: "0px !important",
                          paddingBottom: "0px !important",
                        }}
                      >
                        <p>Reward Withdrawn </p>
                        <div className="wallet-info-amount">
                          <span id="yreward">{annualRewardWithdrawn}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-6">
                      <div
                        className="wallet-info"
                        style={{
                          marginBottom: "0px !important",
                          paddingBottom: "0px !important",
                        }}
                      >
                        <p>Reward Withdrawn </p>
                        <div className="wallet-info-amount">
                          <span id="hreward">{halfYearRewardWithdrawn}</span>
                        </div>
                      </div>
                    </div>
                   


                     <div className="col-md-6 col-6">
                      <div
                        className="wallet-info"
                        style={{
                          marginBottom: "0px !important",
                          paddingBottom: "0px !important",
                        }}
                      >
                        <p>Remaining Reward </p>
                        <div className="wallet-info-amount">
                          <span id="yreward">{annualRemainingReward}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-6">
                      <div
                        className="wallet-info"
                        style={{
                          marginBottom: "0px !important",
                          paddingBottom: "0px !important",
                        }}
                      >
                        <p>Remaining Reward </p>
                        <div className="wallet-info-amount">
                          <span id="hreward">{halfYearRemainingReward}</span>
                        </div>
                      </div>
                    </div>
                   
                   
                    
                    <div className="col-md-6 col-6">
                      <div
                        className="wallet-info"
                        style={{
                          marginBottom: "0px !important",
                          paddingBottom: "0px !important",
                        }}
                      >
                        <p>Total Reward</p>
                        <div className="wallet-info-amount">
                          <span id="yreward">{maxAnnualReward}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-6">
                      <div
                        className="wallet-info"
                        style={{
                          marginBottom: "0px !important",
                          paddingBottom: "0px !important",
                        }}
                      >
                        <p>Total Reward</p>
                        <div className="wallet-info-amount">
                          <span id="hreward">{maxHalfYearReward}</span>
                        </div>
                      </div>
                    </div>
                   
                   
                   
                   
                    <div className="col-md-6 col-6">
                      <div
                        className="secondary-btn text-dark"
                        onClick={() => {
                          setShowPopUp(true);
                        }}
                      >
                        Stake
                      </div>
                    </div>
                    <div
                      className="col-md-6 col-6"
                      onClick={() => {
                        handleWithdraw();
                      }}
                    >
                      <div className="secondary-btn text-dark">Unstake</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="row mh-450">
                  <div className="col-md-12">
                    <div className="dash-transactions">
                      <div className="card-graph">
                        Reward Available for Withdrawl
                      </div>

                      <div
                        className="card-footer flex"
                        style={{
                          justifyContent: "space-between !important",
                          width: "100%",
                        }}
                      >
                        <div>
                          <div className="row">
                            <div className="col-md-12 text-center">
                              <p>Yearly</p>
                              <p className="gray" id="yavailable">
                                {totalAnnualclaimAmount}
                              </p>
                            </div>
                            <div className="col-md-12 flexCenter">
                              <button
                                className="secondary-btn w-100 text-dark"
                                onClick={() => {
                                  handleClaim(true);
                                }}
                              >
                                Claim
                              </button>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="row">
                            <div className="col-md-12 text-center">
                              <p>Half Yearly</p>
                              <p className="gray" id="havailable">
                                {totalHalfYearClaimAmount}
                              </p>
                            </div>
                            <div className="col-md-12 flexCenter">
                              <button
                                className="secondary-btn w-100 text-dark"
                                onClick={() => {
                                  handleClaim(false);
                                }}
                              >
                                Claim
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="token-rates-dash">
                          <p className="card-head">Information</p>
                          <div className="rates-cards row">
                            <div className="rate-card flex col-6">
                              <div className="rate-icon">
                                <i className="fas fa-cog" />
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <p>Affilite Reward</p>
                                </div>
                                <div className="col-md-12">
                                  <p className="orange" id="affReward">
                                    {refReward}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="rate-card flex col-6">
                              <div className="rate-icon">
                                <i className="fas fa-cog" />
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <p>AMMYI Price</p>
                                </div>
                                <div className="col-md-12">
                                  <p className="green">{coinPrice} USD</p>
                                </div>
                              </div>
                            </div>

                            <div className="rate-card flex col-6">
                              <div className="rate-icon">
                                <i className="fas fa-cog" />
                              </div>
                              <div className="row" onClick={() => {}}>
                                <div className="col-md-12">
                                  <p>Your Upline</p>
                                </div>
                                <div className="col-md-12">
                                  <p className="green">{getShortLink()}</p>
                                </div>
                              </div>
                            </div>
                            <div className="rate-card flex col-6">
                              <div className="rate-icon">
                                <i className="fas fa-cog" />
                              </div>
                              <button
                                className="stake-footer-btn"
                                style={{ borderRadius: 20 }}
                                onClick={() => {
                                  refLinkCopy();
                                }}
                              >
                                Get Referral
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12" style={{ marginTop: 10 }}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="token-rates-dash">
                          <p className="card-head">Investment Information</p>
                          <div className="rates-cards row">
                            <table className="bottom-table">
                              <thead>
                                <tr>
                                  <td colSpan={2}>Date</td>
                                  <td colSpan={2}>Amount</td>
                                  <td colSpan={2}>Plan</td>
                                  <td colSpan={2}>Completes </td>
                                </tr>
                              </thead>
                              <tbody>



                               {depositData.map((e)=>{
                                 return  <tr>
                                 <td colSpan={2}>  {moment.unix(e.startDate)
                            .format("DD MMM YYYY")}</td>
                                 <td colSpan={2}>{e.amount}</td>
                                 <td colSpan={2}>{e.isHalfYearly?"Half Yearly":"Annual"}</td>
                                 <td colSpan={2}>

                                 {moment.unix(e.startDate)
                            .add(e.isHalfYearly ? 6 : 12, "months")
                            .format("DD MMM YYYY")}
                               </td>
                               </tr>

                               })}


                             
                           
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
}

export default Home;
